import React, { Fragment, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import MyButton from "component/shared-component/form/MyButton";
import { Grid } from "@mui/material";
import MyTextField from "component/shared-component/form/MyTextField";
import { yupResolver } from "@hookform/resolvers/yup";
import { editUserValidation } from "helpers/ValidationHelper";
import MySelectField from "component/shared-component/form/MySelectField";
import { useUpdateProfile, useRoleDropdown } from "hooks/profile/profile";
import { useSnackbar } from "component/shared-component/snackbar";
import CommonModal from "component/shared-component/common-modal";
import { useDropzone } from "react-dropzone";
import edit from "assets/images/edit.svg";
import avatar from "assets/images/avatar-icon.png"
import { useContextHelper } from "helpers/ContextHelper";
import { profile_image_type } from "helpers/constants";
import PrivateImage from "component/shared-component/PrivateImage";



const MyProfile = (props) => {
    const { open, onClose } = props;
    // console.log(profileData)
    const { profile: profileData } = useContextHelper();

    const { showSnackbar } = useSnackbar();

    const [files, setFiles] = useState(null);

    const { control, reset, handleSubmit, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(editUserValidation),
        defaultValues: {
            ...profileData

        },
    });

    const { mutate: editProfile, isLoading } = useUpdateProfile();
    const { data: roleDropdown } = useRoleDropdown();


    const handleUpdateUser = (values) => {
        console.log(values);

        editProfile(values, {
            onSuccess: (item) => {
                if (item.statusCode === 200) {
                    showSnackbar(item?.data, 'success');
                    onHandleClose();
                    //initialRefetch();
                } else {
                    showSnackbar(item?.message, 'error');
                }
            },
            onError: (error) => {
                showSnackbar(error?.message, 'error');
            }
        });
    };



    const onSubmit = (values) => {
        const formData = new FormData();
        if (files !== null) {
            formData.append("profileImage", files)
        }
        if (profileData) {
            formData.append("internalUserId", profileData?.internalUserId);
        }
        formData.append("firstName", values.firstName.trim())
        formData.append("lastName", values.lastName.trim())
        formData.append("email", values.email.trim())
        if (values.password) {
            formData.append("password", values.password);
            if (profileData) {
                formData.append("confirmPassword", values.confirmPassword);
            } else {
                formData.append("confirmPassword", values.password)
            }
        }
        formData.append("roleId", values.roleId)
        formData.append("status", values.status)
        handleUpdateUser(formData)

    }


    const onHandleClose = () => {
        setFiles(null);
        onClose();
        reset();
    }

    const handleDrop = (acceptedFiles) => {
        setFiles(acceptedFiles[0]);
    }



    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/jpeg': ['.jpeg', '.jpg'],
            'image/png': ['.png'],
        },
        onDrop: handleDrop,
        multiple: false
    });



    useEffect(() => {

        if (profileData) {
            setValue("firstName", profileData?.firstName);
            setValue("lastName", profileData?.lastName);
            setValue("email", profileData?.email);
            setValue("roleId", profileData?.roleId);

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profileData]);

    const imagePreviewUrl = files ? URL.createObjectURL(files) : avatar;

    return (
        <Fragment>
            <CommonModal
                open={open}
                onClose={() => onHandleClose()}
                title="My Profile"
                subTitle="Manage Profile"
                width={800}
                maxWidth={1200}
                content={
                    <>
                        <div className="new-hr" />
                        <br />
                        <div className="create-form">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="media-container">
                                    <figure className="media-object">
                                        <div {...getRootProps()} >
                                            <input {...getInputProps()} />
                                            <div className="edit-icon"> <span><img src={edit} alt="" /></span></div>
                                        </div>
                                        {profileData && profileData?.profileImageUrl !== null && !files ?
                                            <PrivateImage privateUrl={profileData?.profileImageUrl} className="img-object" imgType={profile_image_type} /> : <img className="img-object" src={imagePreviewUrl} alt="" />}

                                        {/* <img className="img-object" src={avatar} alt="avator" /> */}
                                    </figure>
                                </div>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <MyTextField
                                            name="firstName"
                                            control={control}
                                            rules={{ required: 'Enter first name' }}
                                            label="First Name"
                                            autoComplete="off"
                                            error={!!errors.email}
                                            placeholder="Enter first name"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <MyTextField
                                            name="lastName"
                                            control={control}
                                            rules={{ required: 'Enter last name' }}
                                            label="Last Name"
                                            autoComplete="off"
                                            error={!!errors.email}
                                            placeholder="Enter last name"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <MyTextField
                                            name="email"
                                            control={control}
                                            rules={{ required: 'Email is required', pattern: { value: /^\S+@\S+$/i, message: 'Invalid email' } }}
                                            label="Email"
                                            autoComplete="off"
                                            error={!!errors.email}
                                            placeholder="Enter your email"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <MySelectField
                                            name="roleId"
                                            control={control}
                                            defaultValue=""
                                            label="Role"
                                            options={roleDropdown?.data?.map(role => ({
                                                value: role.roleId,
                                                label: role.roleName
                                            })) || []}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <MyTextField
                                            name="password"
                                            control={control}
                                            rules={{ required: 'Password is required' }}
                                            label="Password"
                                            type="password"
                                            autoComplete="off"
                                            passwordWarning={true}
                                            error={!!errors.password}
                                            placeholder="Enter your password to change"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <MyTextField
                                            name="confirmPassword"
                                            control={control}
                                            rules={{ required: 'Password is required' }}
                                            label="Confirm Password"
                                            type="password"
                                            passwordWarning={true}
                                            autoComplete="off"
                                            error={!!errors.confirmPassword}
                                            placeholder="Enter your confirmPassword to confirm"
                                        />
                                    </Grid>
                                </Grid>
                                <div className="modal-btns">
                                    <div className="modal-btns-center">
                                        <MyButton
                                            variant="outlined"
                                            color="primary"
                                            size="large"
                                            className="modal-cancel-btn"
                                            onClick={() => onHandleClose()}
                                        >Cancel</MyButton>
                                        <MyButton
                                            variant="outlined"
                                            color="primary"
                                            size="large"
                                            type="submit"
                                        >Update</MyButton>
                                    </div>
                                </div>
                            </form>
                        </div>

                    </>
                } />
        </Fragment>

    );
};

export default MyProfile;

