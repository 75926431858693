import React from 'react';
import { Controller } from 'react-hook-form';
import {  FormControl, MenuItem, Select, styled } from '@mui/material';

// Styled Select to match design requirements
const StyledSelect = styled(Select)(({ theme, error }) => ({
  boxSizing: 'border-box',
  fontFamily: 'Inter, sans-serif',
  fontSize: '15px',
  lineHeight: '24px',
  height: '44px',
  fontWeight: '500',
  color: '#101828',
  '& .MuiOutlinedInput-notchedOutline': {
    borderRadius: "6px",
    border: "none",
    boxShadow: error ? "#d32f2fa8 0px 1px 1px, #d32f2fa8 0px 0px 1px 1px;" : "rgba(9, 30, 66, 0.25) 0px 0px 1px, rgba(9, 30, 66, 0.13) 0px 0px 0px 1px",
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderRadius: "6px",
    border: "none",
   boxShadow: error ? "#d32f2fa8 0px 1px 1px, #d32f2fa8 0px 0px 1px 1px;" : "rgba(9, 30, 66, 0.25) 0px 0px 1px, rgba(9, 30, 66, 0.13) 0px 0px 0px 1px",
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderRadius: "6px",
    border: "none",
   boxShadow: error ? "#d32f2fa8 0px 1px 1px, #d32f2fa8 0px 0px 1px 1px;" : "rgba(9, 30, 66, 0.25) 0px 0px 1px, rgba(9, 30, 66, 0.13) 0px 0px 0px 1px",
  },
}));

const MySelectField = ({ name, control, label, rules = {}, defaultValue = '', ...rest }) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => {
        const { value } = field;
        const hasValue = value !== null;
         return (
          <FormControl fullWidth error={!!fieldState.error}>
            <h2 className='form-label'>{label}</h2>
            <StyledSelect
              {...field}
              labelId={`${name}-label`}
              displayEmpty={true}
              placeholder='Selct One'
              id={name}
               error={fieldState.error}
              {...rest}
              sx={{
                '.MuiSelect-select': {
                  color: hasValue ? 'inherit' : '#ADB0B6', 
                  fontSize: "15px",
                  fontWeight: "500"
                },
              }}
            >
               <MenuItem value={null} disabled style={{ display: "none" }}>
                Select one
              </MenuItem>
              {rest.options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </StyledSelect>
            {fieldState.error && (
               <span id={`${name}-helper-text`} className='select-error'>
               {fieldState.error.message}
             </span>
            )}
          </FormControl>
        )
      }}
    />
  );
};

export default MySelectField;
