import { useQuery, useMutation } from "react-query";
import * as prod from "../../../service/product/product";



export const useProductList = () => {
    return useMutation((formData) => prod.productList(formData));
}

export const useAddProduct = () => {
    return useMutation((formData) => prod.addProduct(formData));
}

export const useEditProduct = () => {
    return useMutation((formData) => prod.editProduct(formData));
}

export const useDeleteProduct = () => {
    return useMutation((formData) => prod.deleteProduct(formData));
}
export const useStatusChange =()=>{
    return useMutation((formData) =>prod.statusChange(formData));
}

 export const useRfidDropdown =() =>{
    return useQuery("rfidDropdown",()=>prod.prodRfidDropdown(),{
        refetchOnWindowFocus:false,
        refetchOnReconnect: true,
    })
 }
 export const useRfidDropdownById =() =>{
    return useMutation((formData)=>prod.prodRfidDropdownbyId(formData)
    )
 }
 export const useProdCategoryDropdown =() =>{
    return useQuery("prodCategoryDropdown",()=>prod.prodCategoryDropdown(),{
        refetchOnWindowFocus:false,
        refetchOnReconnect: true,
    })
 }

 export const useProdLocationDropdown =() =>{
    return useQuery("ProdLocationDropdown",()=>prod.prodLocationDropdown(),{
        refetchOnWindowFocus:false,
        refetchOnReconnect: true,
    })
 }

 export const useBulkUpload = () =>{
    return useMutation((formData) =>prod.bulkUpload(formData));
}

export const useUpdateBulkUpload = () =>{
    return useMutation((formData) =>prod.updateBulkUpload(formData));
}