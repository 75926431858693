import React from 'react';
import {  Navigate, Outlet } from 'react-router-dom';
import { useContextHelper } from '../../helpers/ContextHelper';

const ProtectedRoute = ({ roles }) => {
  const { user } = useContextHelper();

  if (!user.isAuthenticated) {
    return <Navigate to="/login" />;
  }

  if (!roles.includes(user.role)) {
    return <Navigate to="/" />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
