import React, { Fragment, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { Grid } from "@mui/material";
import Header from "./utils/app/header";
import globalRouter from "globalRouter.ts";

const AppLayouts = ({initialRefetch}) => {

  const [logout, setLogout] = useState(false);

  // const timeout = 1800000; // 30 minutes in milliseconds
  const timeout = 3600000; // 1 hour in milliseconds

  useEffect(() => {
      let timer;

      const resetTimer = () => {
          clearTimeout(timer);
          timer = setTimeout(() => setLogout(true), timeout);
      };

      const events = [
          'load',
          'mousemove',
          'mousedown',
          'click',
          'scroll',
          'keypress'
      ];

      const resetEvents = () => {
          events.forEach(event => {
              window.addEventListener(event, resetTimer);
          });
      };

      resetTimer();
      resetEvents();

      return () => {
          clearTimeout(timer);
          events.forEach(event => {
              window.removeEventListener(event, resetTimer);
          });
      };
  }, [timeout]);

  useEffect(() => {
      if (logout) {
          // Perform logout actions here, e.g., redirect to login page
          localStorage.removeItem('token');
          globalRouter.navigate("/login", { state: { message: "Session Time Out! Please ReLogin." } });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logout]);

  useEffect(() => {
    initialRefetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

  return (
    <Fragment>
      <Grid container>
        <Grid item xs={12}>
          <Header />
        </Grid>
        <Grid item xs={12}>
          <Outlet />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default AppLayouts;
