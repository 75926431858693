import React, { createContext, useContext, useMemo, useState } from 'react';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { createTheme } from '@mui/material/styles';


const ThemeContext = createContext();

export const useThemeContext = () => {
  return useContext(ThemeContext);
};

const ThemeHelper = ({ children }) => {
  const [mode, setMode] = useState('light');

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
        },
        typography: {
          fontFamily: 'Roboto, Arial, sans-serif',
        },
        components: {
          MuiButton: {
            styleOverrides: {
              root: {
                fontFamily: "Inter",
                fontSize: "15px",
                fontWeight: "600",
                lineHeight: "24px",
                width: "100%",
                borderRadius: "6px",
                textTransform: 'none',
                backgroundColor: '#31A566',
                border: "none",
                color: 'white',
                '&:hover': {
                  backgroundColor: '#31A566',
                  border: "none"
                },
                '&:disabled': {
                  backgroundColor: '#d0d0d0',
                  color: "#838080",
                  border: "none"
                },
              },
            },
          },
        },
      }),
    [mode]
  );

  const toggleTheme = () => {
    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };

  return (
    <ThemeContext.Provider value={{ mode, toggleTheme }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeHelper