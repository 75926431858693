import React, { Fragment } from "react";
import { Navigate, Outlet } from "react-router-dom";

const AuthLayouts = () => {
  const auth = localStorage.getItem("token") ? true : false;
  return auth ? <Navigate to="/dashboard" /> :
    <Fragment>
         <main><Outlet/></main>
    </Fragment>
};

export default AuthLayouts;
