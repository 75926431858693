import NetworkmultipartClient from "helpers/networkMultiPartClient";
import NetworkClient from "helpers/networkClient";




const ProfileDetails = async () => {
    if (localStorage.getItem('token') === null) {
        return null;
    }
    const { data } = await NetworkClient.get("/user/profileDetails");
    return data;
};


const editProfile = async(formData) =>{
    const {data} = await NetworkmultipartClient.put("/user/editProfile",formData);
    return data;
}

const roleDropdown = async() =>{
    const {data} = await NetworkClient.get("/role/dropdown");
    return data;
}

export{editProfile,roleDropdown,ProfileDetails}