import React, { useState } from 'react';
import "./style.css"
import MyProfile from 'views/app/profile';
import { Logout } from '@mui/icons-material';
import logo from "../../../assets/images/logo.png";
import { NavLink, useNavigate } from 'react-router-dom';
import { useContextHelper } from 'helpers/ContextHelper';
import { Avatar, Divider, IconButton, ListItemIcon, Menu, MenuItem, Tooltip } from '@mui/material';
import avatar from "assets/images/avatar-icon.png"
import { profile_image_type } from "helpers/constants";
import PrivateImage from "component/shared-component/PrivateImage";

const Header = () => {

    const { profile: profileData } = useContextHelper();

    let navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const { setUser } = useContextHelper();
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [openDialog, setOpenDialog] = useState(false);

    const onShow = (val) => {
        setOpenDialog(true);
        setAnchorEl(null);
    }

    const onClose = () => {
        setOpenDialog(false);
    }

    const logout = () => {
        localStorage.clear();
        setUser({ role: "", isAuthenticated: false });
        navigate("/login")
    }

    return (
        <header className="app-header">
            <div className="app-logo">
            <NavLink to="/dashboard" ><img src={logo} alt="Logo" className='logo' /></NavLink>
            </div>
            <div className="app-links">
                <NavLink to="/dashboard" >Dashboard</NavLink>
                <NavLink to="/reports" >Reports</NavLink>
                <NavLink to="/configuration">BLE</NavLink>
                {/* <NavLink to="/inventory">Inventory</NavLink> */}
                <NavLink to="/user/admin">Users</NavLink>
            </div>
            <div >
                <Tooltip title="">
                    <div className='profile-settings'>
                        <IconButton
                            onClick={handleClick}
                            size="small"
                            sx={{ ml: 2 }}
                            aria-controls={open ? 'account-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                        >
                            <div className=' '>
                                {profileData && profileData?.profileImageUrl !== null ? <PrivateImage privateUrl={profileData?.profileImageUrl} className="profile_img" imgType={profile_image_type} /> : <img className="profile_img" src={avatar} alt="" />}

                            </div>
                        </IconButton>
                        <div>
                            <p><b>{profileData?.firstName} {profileData?.lastName}</b></p>
                            <p>{profileData?.roleName}</p>
                        </div>
                    </div>
                </Tooltip>

                <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&::before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    <MenuItem onClick={onShow}>
                        {profileData && profileData?.profileImageUrl === null ? <Avatar /> : <PrivateImage privateUrl={profileData?.profileImageUrl} className="profile_img_sub" imgType={profile_image_type} />} My Profile
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={logout}>
                        <ListItemIcon>
                            <Logout fontSize="small" />
                        </ListItemIcon>
                        Logout
                    </MenuItem>
                </Menu>
            </div>
            <MyProfile open={openDialog} onClose={onClose} />
        </header>
    );
};

export default Header;
