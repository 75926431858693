import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import BrainHelpers from './helpers/BrainHelpers';
import "./assets/css/style.css";
import "./assets/buttonCss/style.css";
import App from './App';
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from 'react-router-dom';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
   <QueryClientProvider client={queryClient}>
     <BrainHelpers>
        <BrowserRouter>
          <App />
        </BrowserRouter>
     </BrainHelpers>
     </QueryClientProvider>
  </React.StrictMode>
);
reportWebVitals();
