import {useQuery,useMutation ,useQueryClient} from "react-query";
import * as profile from "service/profile/profile"


export const useProfileDetails = () => {
    return useQuery("profileDetails", () => profile.ProfileDetails(), {
        enabled: false,
        retry: false,
        refetchOnReconnect: true,
        refetchOnWindowFocus: false
    })
}


export const useUpdateProfile = () =>{
    const queryClient = useQueryClient();
    return useMutation((formData) =>profile.editProfile(formData),{
        onSuccess: () => {
            queryClient.refetchQueries('profileDetails');
        }
    }
);
}

export const useRoleDropdown =() =>{
    return useQuery("RoleDropdown",() =>profile.roleDropdown(),{
        refetchOnWindowFocus:false,
        refetchOnReconnect:true,
    })
}
