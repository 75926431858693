import React from 'react';
import { Pagination as MuiPagination, PaginationItem, Stack, Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { styled } from '@mui/material/styles';


const StyledPaginationItem = styled(PaginationItem)(({ theme }) => ({
  fontFamily: "Inter",
  fontSize: "14px",
  fontWeight: "600",
  lineheight: "20px",
  color: " #1D2939",
  '&:hover': {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "600",
    lineheight: "20px",
    color: " #1D2939",
    backgroundColor: '#F9FAFB',
  },
  '&.Mui-selected': {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "600",
    lineheight: "20px",
    color: " #1D2939",
    backgroundColor: '#F9FAFB',
    '&:hover': {
      backgroundColor: '#F9FAFB',
    },
  },
}));


const MyPagination = ({
  count,
  page,
  onPageChange,
  siblingCount = 1,
  boundaryCount = 1,
  ...props
}) => {
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
      <Button onClick={(e) => onPageChange(e, Math.max(1, page - 1))} style={{ backgroundColor: "transparent" }} disabled={page === 1} startIcon={<ArrowBackIcon size="30px" />} className="previousbtn">Previous</Button>
      <MuiPagination
        count={count}
        page={page}
        onChange={onPageChange}
        siblingCount={siblingCount}
        boundaryCount={boundaryCount}
        hidePrevButton
        hideNextButton
        renderItem={(item) => (
          <StyledPaginationItem {...item} />
        )}
        {...props}
      />
      <Button onClick={(e) => onPageChange(e, Math.min(count, page + 1))} style={{ backgroundColor: "transparent" }} disabled={page === count} className="nextbtn" endIcon={<ArrowForwardIcon size={"30px"} />}>Next</Button>
    </Stack>
  );
};

export default MyPagination;
