import React from 'react';
import { Dialog, Slide } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const CommonModal = ({ open, onClose, content, width, maxWidth, title, subTitle, sampleFile }) => {
    return (
        <Dialog
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
            sx: {
                padding: "0px 20px 20px 20px",
                width: `${width}px`,
                maxWidth: `${maxWidth}px`,
                borderRadius: "10px",
                position: "sticky",
                top: "0px"
            }
        }}
    >
        <div className={`model--header ${sampleFile ? "model-sample-header" : "model--header"}`}>
            <div className="modal-title">
                <h1>{title}</h1>
                <p>{subTitle}</p>
            </div>
            {
                sampleFile && <a href={sampleFile} download="SampleCSV.csv">
                <h4>Sample CSV</h4>
            </a>
            }
            <CloseIcon onClick={onClose} />
        </div>
        {content}
    </Dialog>
    );
};

export default CommonModal;
