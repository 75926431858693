import { createContext, useContext, useState } from "react";

const LocalContext = createContext();

export function useContextHelper() {
  return useContext(LocalContext);
}

function ContextProvider({children}) {
    const storedData = localStorage.getItem('role');
    const [user, setUser] = useState({ role: storedData, isAuthenticated: true });
    const [profile, setProfile] = useState([]);

  return (
    <LocalContext.Provider value={{ user, setUser,profile,setProfile }}>
      {children}
    </LocalContext.Provider>
  );
}

export default ContextProvider;

