import axios from "axios";
import globalRouter from "globalRouter.ts";

const SECONDS = 30;
const MILISECONDS = 1000;
const TIMEOUT = SECONDS * MILISECONDS;
const REACT_APP_BASE_API = process.env.REACT_APP_BASE_API;

const NetworkClient = axios.create({
    baseURL: REACT_APP_BASE_API,
    timeout: TIMEOUT,
    headers: {
        "Content-Type": "application/json",
    },
});

NetworkClient.interceptors.request.use(config => {
    const jwtToken = localStorage.getItem("token")
    if (jwtToken) {
        config.headers["authorization"] = 'Bearer ' + jwtToken
    }
    if (!jwtToken && !config.headers["public-request"]) {
    }
    return config
}, error => {
    return Promise.reject(error)
})

NetworkClient.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error?.response?.data) {

        } else if (error.response.status !== 200) {
            if (error.response.status === 401 && globalRouter.navigate) {
                localStorage.removeItem('token');
                globalRouter.navigate("/login", { state: { message: "Session Time Out! Please ReLogin." } });
            } else {
                return error?.response?.data;
            }
        } else {
            console.error(error);
        }
        return Promise.reject(error?.response?.data);
    }
);

export default NetworkClient;