import React, { useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import { IconButton, InputAdornment, styled } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Controller } from 'react-hook-form';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import CustomTooltip from 'component/widgets/common-tooltip';



const CustomOutlinedInput = styled(OutlinedInput)(({ theme, error  }) => ({
  boxSizing: 'border-box',
  fontFamily: 'Inter, sans-serif',
  fontSize: '15px',
  lineHeight: '24px',
  height: '44px',
  fontWeight: '500',
  color: '#101828',
  '& .MuiOutlinedInput-notchedOutline': {
    borderRadius: "6px",
    border: "none",
    boxShadow: error ? "#d32f2fa8 0px 1px 1px, #d32f2fa8 0px 0px 1px 1px;" : "rgba(9, 30, 66, 0.25) 0px 0px 1px, rgba(9, 30, 66, 0.13) 0px 0px 0px 1px",
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderRadius: "6px",
    border: "none",
   boxShadow: error ? "#d32f2fa8 0px 1px 1px, #d32f2fa8 0px 0px 1px 1px;" : "rgba(9, 30, 66, 0.25) 0px 0px 1px, rgba(9, 30, 66, 0.13) 0px 0px 0px 1px",
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderRadius: "6px",
    border: "none",
   boxShadow: error ? "#d32f2fa8 0px 1px 1px, #d32f2fa8 0px 0px 1px 1px;" : "rgba(9, 30, 66, 0.25) 0px 0px 1px, rgba(9, 30, 66, 0.13) 0px 0px 0px 1px",
  },
}));


const StyledInputLabel = styled(InputLabel)(({ theme,}) => ({
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: '500',
  lineHeight: '20px',
  textAlign: 'left',
  marginBottom: '6px',
  color: "#344054",
  display: 'flex',
  alignItems: 'center',
}));

const MyTextField = ({ name, control, rules,autoComplete, defaultValue, errorMessage, disabled,maxLength, type, placeholder, label, passwordWarning, ...rest }) => {
  const isPassword = type === 'password';
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  


  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}

      render={({ field, fieldState }) => (
        <>
        <StyledInputLabel htmlFor={name}>{label}  {(isPassword && passwordWarning) && (
              <CustomTooltip title="At least one letter, one number, one symbol, 9 to 20 characters" placement="right">
                <HelpOutlineRoundedIcon style={{ marginLeft: 5, width: "18px", cursor: "pointer"}} />
              </CustomTooltip>
            )} </StyledInputLabel>

          <CustomOutlinedInput
            {...field}
            {...rest}
            id={name}
            autoComplete={autoComplete} 
            disabled={disabled}
            aria-describedby={`${name}-helper-text`}
            error={!!fieldState.error}
            type={isPassword ? (showPassword ? 'text' : 'password') : type}
            fullWidth
            placeholder={placeholder}
            inputProps={{ maxLength: maxLength  ,name:name  }}
               endAdornment={
                isPassword && (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label={showPassword ? 'Hide password' : 'Show password'}
                      onClick={togglePasswordVisibility}
                      edge="end"
                    >
                      {!showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                 
                )
              }
          />
           <div className='form-err-msg'>
            {fieldState.error || errorMessage ? (
              <span id={`${name}-helper-text`} className='input-error'>
                {fieldState.error?.message || errorMessage}
              </span>
            ) : null}
          </div>

        </>
      )}
    />
  );
};

export default MyTextField;
