import React from 'react';
import { Button as MuiButton, CircularProgress  } from '@mui/material';

const MyButton = ({
  type,
  variant,
  color ,
  size,
  onClick,
  fontSize,
  className,
  disabled = false,
  startIcon,
  endIcon,
  children,
  loading = false,
  ...props
}) => {
  return (
    <MuiButton
      type={type}
      variant={variant}
      color={color}
      size={size}
      onClick={onClick}
      disabled={disabled}
      startIcon={startIcon}
      endIcon={endIcon}
      style={{ fontSize }}
      className={className}
      {...props} 
    >
      {loading && (
        <CircularProgress
          size={24}
          color="inherit"
          style={{
            marginRight: "10px"
          }}
        />)}
        {children}
    </MuiButton>
  );
};

export default MyButton;
