import React from 'react';
import { QueryClient, QueryClientProvider, Hydrate } from 'react-query';

import { Toaster } from 'sonner';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import ThemeHelper from './ThemeHelper';
import ContextProvider from './ContextHelper';
import { SnackbarProvider } from 'component/shared-component/snackbar';


const queryClient = new QueryClient();

const cache = createCache({
  key: 'css',
  prepend: true,
});

const BrainHelpers = ({ children, dehydratedState }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={dehydratedState}>
        <CacheProvider value={cache}>
          <ContextProvider>
          <SnackbarProvider>
            <ThemeHelper>
               <Toaster />
               {children}
            </ThemeHelper>
            </SnackbarProvider>
          </ContextProvider>
        </CacheProvider>
      </Hydrate>
    </QueryClientProvider>
  );
};
export default BrainHelpers;
